
import { defineComponent, PropType } from 'vue';
import { Student } from '@/models';
import Routes from '@/router/Routes';
import Class from '../../models/Class';

export default defineComponent({
  components: {},
  props: {
    student: {
      type: Object as PropType<Student>,
      required: true
    },
    classData: {
      type: Object as PropType<Class>,
      required: true
    }
  },
  setup(props) {
    const courseLabel = props.classData.courseId
      ? props.classData.courseId
      : 'No course assigned';
    return {
      courseLabel,
      Routes
    };
  }
});
